.book-content {
  section {
    width: auto;
  }
  .threeBox {
    width: 95%;
    border: 1px solid rgba(215, 215, 215, 1);
    padding: 2%;
    min-height: 200px;
  }
  .threeBox div:nth-child(1) {
    border-right: 2px solid rgba(237, 136, 128, 1);
  }
  .threeBox div:nth-child(2) {
    border-right: 2px solid rgba(250, 183, 55, 1);
  }
  .threeBox div:nth-child(3) {
    border-right: 2px solid rgba(133, 199, 165, 1);
  }
  img,
  .insertedImage {
    max-width: 100%;
    width: 100%;
  }
  .Body {
    background-color: transparent !important;
    font-size: 1em !important;
    font-weight: normal;
  }
  .Grey-Box {
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(20, 20, 20, 1);
    padding: 1%;
    margin-bottom: 1%;
    display: inline-block;
    width: 98%;
  }
  .Grey-Box p {
    margin-top: 1%;
    width: 100%;
  }
  .Rounded-Number {
    float: left;
    border-radius: 50%;
    color: #ffffff;
    background-color: #2f3858;
    padding: 2px 7px 2px 7px;
    margin: 0 2% 0 0;
  }
  thead {
    display: table-row-group;
  }
  .Header,
  .main-header {
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.2em;
    margin-top: 0 !important;
  }
  .Bold-Italic {
    font-weight: bold !important;
    font-style: italic !important;
  }
  .Bold,
  .Sub-Header,
  .Sub-Header.ParaOverride-3 {
    font-weight: bold !important;
  }
  .bold-underline {
    font-weight: bold !important;
    text-decoration: underline !important;
  }
  .bold-italic-underline {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
  }
  a,
  .Hyperlink {
    color: #0000ee !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    text-decoration: underline;
  }
  a:visited {
    color: #0000ee !important;
    text-decoration: underline;
  }
  table {
    background-color: white;
    color: #2f3858;
    padding: 20px 15px 50px 15px;
    border-collapse: collapse;
    page-break-inside: always;
  }
  td .Bold {
    font-weight: bold;
  }
  .CellOverride-1 {
    vertical-align: top;
  }
  .Table-Style-1,
  .Basic-Table,
  .insertedTable {
    border: 1px solid rgba(20, 20, 20, 1);
  }
  .Table-Style-1 td,
  .Basic-Table td,
  .insertedTable td {
    border: 1px solid rgba(20, 20, 20, 1);
    vertical-align: top;
    padding: 1px 5px 1px 5px !important;
  }
  tr:nth-child(even) {
    background: #eee;
  }
  .Bullets-level1 {
    margin-bottom: 5px;
  }
  .Bullets-level2 {
    margin: 5px 0;
  }
  .Bullets-level-3-square {
    margin: 5px 0;
  }
  .Bullet-level4 {
    margin: 5px 0;
  }
  ._idGenObjectAttribute-1 {
    width: 10px;
    margin-right: 5px;
  }
  ul {
    margin-left: -10px;
  }
  ul ul {
    margin-left: -20px;
  }
  b {
    font-weight: bold !important;
  }
  #contentsPage [id*="tab"] a {
    text-decoration: none !important;
    color: #000000;
  }
  .page {
    font-size: 8pt;
    margin-top: 0px;
  }
  .printOnly {
    page-break-before: always;
    width: 100% !important;
  }
  .page-header,
  .page-header-space {
    height: 10px;
    border: 1px solid red;
  }
  .printOnly thead {
    display: table-header-group;
    margin-bottom: 300px;
  }
  .printOnly th {
    font-weight: bold;
    height: 0.5cm;
    text-transform: uppercase;
  }
  .printOnly tbody tr {
    page-break-inside: avoid;
  }
  .printOnly hr {
    color: #000000;
    background-color: #000000;
    border: 1px solid #000000;
  }
  footer {
    text-align: center;
    padding: 1% 0 1% 0;
    margin-top: 8px;
    vertical-align: text-bottom;
  }
  header {
    text-align: center;
    padding: 2% 0 0.5% 0;
  }
  header h2 {
    font-weight: bold;
    font-size: 1.15em;
    margin-bottom: 2px;
  }
  header hr,
  footer hr {
    background-color: #000000;
    height: 2px;
    margin-top: 0;
    border: none;
  }
  footer p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 8pt;
  }
  .Kiss {
    padding-top: 0 !important;
    width: 100%;
    background-color: rgba(255, 255, 150, 0.3);
  }
  .Kiss * {
    background-color: rgba(255, 255, 150, 0.3);
  }

  .screenshots {
    text-align: center;
  }
  .screenshots table {
    text-align: center;
    width: 850px;
  }
  .screenshots td {
    text-align: center;
  }
  .screenshots td img {
    width: auto !important;
  }
  .screenshots img {
    height: 20% !important;
    margin-right: 8%;
    width: 25%;
    float: left;
  }
  .textCenter {
    text-align: center;
  }
  li {
    margin-top: 0;
    margin-bottom: 0;
  }
  .Red-Cell {
    background-color: rgba(237, 136, 128, 1);
    color: rgba(255, 255, 255, 1);
  }
  .Header-Cell {
    background-color: rgba(49, 133, 157, 1);
    font-weight: bold;
    text-align: center;
    color: rgba(255, 255, 255, 1) !important;
  }
  .Amber-Cell {
    background-color: rgba(250, 183, 55, 1);
    color: rgba(255, 255, 255, 1);
  }
  .Green-Cell {
    background-color: rgba(133, 199, 165, 1);
    color: rgba(255, 255, 255, 1);
  }
  .noTopMargin {
    margin-top: 0 !important;
  }
  .noBotttomMargin {
    margin-bottom: 0 !important;
  }
  .noMarginBoth {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .White-Cell {
    background-color: rgb(255, 255, 255);
  }

  .Light-Grey-Cell {
    background-color: rgb(215, 215, 215);
  }

  .Mid-Grey-Cell {
    background-color: rgb(170, 170, 170);
  }

  .Dark-Grey-Cell {
    background-color: rgb(115, 115, 115);
  }
  ul {
    margin-left: -10px;
    list-style-type: square;
  }

  ul ul {
    margin-left: -20px;
    list-style-type: disc;
  }

  ul ul ul {
    list-style-type: circle;
  }
  .hasBreak {
    display: none;
  }
}
